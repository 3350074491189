import React from 'react'
import _ from 'lodash'
import { Link } from 'gatsby'
import { generateTagLink, generateUrl } from '../../utils/util'
import PostHeader from '../PostHeader'
import SocialNetworks from '../SocialNetworks';

import * as styles from './styles.module.css'

const PostLink =  ({ post }) => {
    const {
        author,
        title,
        date,
        tags,
        image
    } = post.frontmatter
    const { slug, readingTime } = post.fields
    const { excerpt: text } = post

    const dir = _.trim(slug, '/');
    const postSlug = generateUrl(dir);
    const postUrl = `/blog/post/${postSlug}`;

    return (
        <div className={styles.content}>
            <PostHeader
                image={image}
                title={title}
                dir={dir}
                authorInfo={author}
                date={date}
                postSlug={postSlug}
                readingTime={readingTime}
                tags={tags}
                text={text}
                needLink
            />

            <div className={styles.postWrapper}>
                <Link
                    to={`${postUrl}`}
                    className={styles.postLink}
                >
                    <h2 className={styles.title}>{title}</h2>
                </Link>

                <Link
                    to={`${postUrl}`}
                    className={styles.postLink}
                >
                    <div className={styles.text}>{text}</div>
                </Link>

                <div className={styles.socialNetworks}>
                    <SocialNetworks
                        title={title}
                        text={text}
                        tags={tags}
                        url={postUrl}
                    />
                </div>

                <div className={styles.footer}>
                    {tags.map((item) => (
                        <Link
                            to={`/blog/tag/${generateTagLink(item)}`}
                            className={styles.tag}
                            key={item}
                        >
                            #{item}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PostLink
