import React from 'react'
import { Link } from 'gatsby'
import * as paginationStyles from './paginationStyles.module.css'
import cx from 'classnames'

const Pagination = ({ numPages, currentPage, section, value }) => {
    const generateLink = (i) => {
        switch (section) {
            case 'blog':
                return `/blog/${i === 0 ? '' : i + 1}`
            case 'tags':
                return `/blog/tag/${value}/${i === 0 ? '' : i + 1}`
            default:
                return `/blog/${i === 0 ? '' : i + 1}`
        }
    }

    const pageIds = [];
    for(let i = Math.max(currentPage - 3, 0); i < Math.min(currentPage + 3, numPages); i++) {
        pageIds.push(i);
    }

    return (
        <div className={paginationStyles.paginationWrapper}>
            <div className={paginationStyles.pagination}>
                {pageIds.map((i) => (
                    <Link
                        to={generateLink(i)}
                        className={cx(paginationStyles.paginationItem, {
                            [paginationStyles.paginationItemCurrent]: (i + 1 === currentPage)
                        })}
                        key={`pagination-number${i + 1}`}
                    >
                        {i + 1}
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default Pagination
