import React from 'react'
import { graphql } from 'gatsby'
import BlogPosts from '../../components/BlogPosts'
import Layout from '../../components/Layout'
import Pagination from '../../components/Pagination'

const BlogList = ({
    data: {
        allMarkdownRemark: { edges },
    },
    pageContext,
}) => {
    const { numPages, currentPage } = pageContext

    return (
        <Layout
            sectionName="blog"
            seoTitle="Kubevious :: Blog"
            seoDescription="Learn more about kubevious and kubernetes in our blog"
            seoUrl="/blog"
            seoImage="/screens/site-blog.png"
        >
            <BlogPosts edges={edges} />

            <Pagination
                currentPage={currentPage}
                numPages={numPages}
                section="blog"
            />
        </Layout>
    )
}

export default BlogList

export const pageQuery = graphql`
    query blogListQuery($limit: Int!, $skip: Int!) {
        allMarkdownRemark(
            filter: { fields: {sourceInstanceName: {eq: "blog"}} }
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 280)
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        tags
                        author {
                            name
                            avatar
                        }
                    }
                    fields {
                        slug
                        readingTime {
                            minutes
                        }
                    }
                }
            }
        }
    }
`
