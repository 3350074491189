import React from 'react'
import * as styles from './styles.module.css'
import PostLink from '../PostLink'

const BlogPosts = ({ edges }) => {

    const posts = edges
        .filter(edge => !!edge.node.frontmatter.date)
        .map(edge => <PostLink key={edge.node.id} post={edge.node}/>)

    return (
        <div className={styles.content}>
            <div className={styles.container}>
                {posts}
            </div>
        </div>
    )
}

export default BlogPosts
